<template>
  <div id="Login" class="login fullheight" :class="{ 'c-dark-theme': $store.state.darkMode }" style="background-image: url('/img/fondo_registro.png');background-size: cover;">
    <CContainer fluid class="fullheight"> 
      <CRow class="align-start align-md-center fullheight">
        <CCol cols="12" md="12" :style="!is_finished ? 'margin-top: 30px !important' : 'margin-top: 100px !important'">
          <div class="login__container" style="max-width: 600px !important; margin: auto; padding: 30px 20px;">
            <div class="login__inner" style="max-width: 500px !important;" v-show="is_finished">
              <div class="login__image">
                <div style="width: 100%; text-align: center;">
                  
                </div>
                <br><br><br> La cuenta ha sido creada exitosamente.<br><br>
                Hemos enviado un correo de confirmación a tu cuenta de correo electrónico, en el cual podrás confirmar tu cuenta, activarla y definir tu contraseña de acceso.
              </div>
              <div class="login__form">
                <CRow>
                  <CCol sm="12">
                    <br><CButton type="button" color="primary" style="width: 100%" @click="back">VOLVER</CButton>
                  </CCol>
                </CRow>
              </div>
            </div>
            <div class="login__inner" style="max-width: 500px !important" v-show="!is_finished">
              <div class="login__image">
                <div style="width: 100%; text-align: center;">
                  <img src="/img/logotipo.png" alt="Logo Oscuro" style="width: 280px !important; margin:auto;">
                </div>
                <br><br><br> Crea tu cuenta y comienza a facturar con Orderly.<br><br>
              </div>
              <div class="login__form">
                <CRow>
                  <CCol sm="4">
                    <CSelect
                        label="Tipo Persona: "
                        :isValid="checkIfValid('type')"
                        :value.sync="$v.form.type.$model"
                        :options="[{value: 'physical', label: 'Física'}, {value: 'moral', label: 'Moral'}]"
                        placeholder=""
                        invalidFeedback="Este campo es requerido."
                    />
                  </CCol>
                  <CCol sm="8">
                    <CInput
                        :isValid="checkIfValid('rfc')"
                        :value.sync="$v.form.rfc.$model"
                        label="RFC"
                        placeholder=""
                        invalidFeedback="El RFC es un campo obligatorio."
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CInput
                      :isValid="checkIfValid('name')"
                      :value.sync="$v.form.name.$model"
                      label="Nombre o Razón Social"
                      placeholder=""
                      invalidFeedback="El nombre o razón social es un campo obligatorio."
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CSelect
                        label="Régimen Fiscal: "
                        :isValid="checkIfValid('regimen')"
                        :value.sync="$v.form.regimen.$model"
                        :options="regimensOptions"
                        placeholder=""
                        invalidFeedback="Este campo es requerido."
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="8">
                    <CInput
                      :isValid="checkIfValid('commercial_name')"
                      :value.sync="$v.form.commercial_name.$model"
                      label="Nombre Comercial"
                      placeholder=""
                      invalidFeedback="El nombre comercial es un campo obligatorio."
                    />
                  </CCol>
                  <CCol sm="4">
                    <CInput
                      :isValid="checkIfValid('phone')"
                      :value.sync="$v.form.phone.$model"
                      label="Teléfono"
                      placeholder=""
                      invalidFeedback="El teléfono es un campo obligatorio."
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol :sm="show_password ? 6 : 12">
                    <CInput
                      :isValid="checkIfValid('email')"
                      :value.sync="$v.form.email.$model"
                      label="Correo Electrónico"
                      placeholder=""
                      invalidFeedback="El correo es un campo obligatorio y debe tener el formato correcto."
                    />
                  </CCol>
                  <CCol sm="6" v-if="show_password">
                    <CInput
                      :value.sync="$v.form.password.$model"
                      label="Contraseña"
                      type="password"
                      autocomplete="off"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="8">
                    <CSelect
                        label="Estado: "
                        :isValid="checkIfValid('state')"
                        :value.sync="$v.form.state.$model"
                        :options="statesOptions"
                        placeholder=""
                        invalidFeedback="Este campo es requerido."
                    />
                  </CCol>
                  <CCol sm="4">
                    <CSelect
                        label="Zona Horaria: "
                        :value.sync="$v.form.huso_horario.$model"
                        :options="[{value: 'CENTRO', label: 'Centro'}, {value: 'PACIFICO', label: 'Pacífico'}, {value: 'NOROESTE', label: 'Noroeste'}, {value: 'SURESTE', label: 'Sureste'}]"
                        placeholder=""
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="6">
                    <CSelect
                        label="Tipo Empresa: "
                        :isValid="checkIfValid('company_type')"
                        :value.sync="$v.form.company_type.$model"
                        :options="[{value: 'services', label: 'Servicios'}, {value: 'marketer', label: 'Comercializadora'}, {value: 'manufacturing', label: 'Manufacturera'}]"
                        placeholder=""
                        invalidFeedback="Este campo es requerido."
                    />
                  </CCol>
                  <CCol sm="6">
                    <CSelect
                        label="Tamaño Empresa: "
                        :isValid="checkIfValid('company_size')"
                        :value.sync="$v.form.company_size.$model"
                        :options="[{value: 'micro', label: 'De 1 a 10'}, {value: 'small', label: 'De 11 a 20'}, {value: 'medium', label: 'De 20 en adelante'}]"
                        placeholder=""
                        invalidFeedback="Este campo es requerido."
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol :sm="form.admin_tool == 'system' ? 6 : 12">
                    <CSelect
                        label="Llevo mi administración en: "
                        :isValid="checkIfValid('admin_tool')"
                        :value.sync="$v.form.admin_tool.$model"
                        :options="[{value: 'excel', label: 'Excel'}, {value: 'system', label: 'Otro Sistema'}, {value: 'none', label: 'No llevo'}]"
                        placeholder=""
                        invalidFeedback="Este campo es requerido."
                    />
                  </CCol>
                  <CCol sm="6" v-show="form.admin_tool == 'system'">
                    <CSelect
                        label="Sistema: "
                        :isValid="checkIfValid('system')"
                        :value.sync="$v.form.system.$model"
                        :options="[
                            {
                                label: 'Quickbooks',
                                value: 'Quickbooks'
                            },
                            {
                                label: 'Aspel',
                                value: 'Aspel'
                            },
                            {
                                label: 'Contpaq',
                                value: 'Contpaq'
                            },
                            {
                                label: 'Bind',
                                value: 'Bind'
                            },
                            {
                                label: 'Zoho',
                                value: 'Zoho'
                            },
                            {
                                label: 'Facturama',
                                value: 'Facturama'
                            },
                            {
                                label: 'Factura Fácil',
                                value: 'Factura Fácil'
                            },
                            {
                                label: 'Otro',
                                value: 'Otro'
                            },
                        ]"
                        placeholder=""
                        invalidFeedback="Este campo es requerido."
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CButton type="button" color="primary" style="width: 100%" @click="singup">REGISTRATE</CButton>
                  </CCol>
                </CRow>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </CContainer>
    <loading :active="visible" :can-cancel="true"></loading>
    <CToaster placement="top-end">
      <template v-for="(toast, key) in toasts">
        <CToast :color="toast.color" v-bind="toast" :key="'toast' + key" :show="true">
          {{toast.content}}
        </CToast>
      </template>
    </CToaster>
  </div>
</template>

<script>

import router from '../router/index'
import login from '../services/login';
import general_ws from '../services/general';
import Vue from 'vue';
import store from '../store';
import Loading from 'vue-loading-overlay/dist/vue-loading.js';;
import 'vue-loading-overlay/dist/vue-loading.css';

import axios from 'axios'

import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, email} from "vuelidate/lib/validators"

export default {
    name: 'NewAccountForm',
    components: {
      Loading
    },
    data () {
        return {
            is_finished: false,
            form: {
                type: 'physical',
                rfc: '',
                name: '',
                regimen: '626',
                commercial_name: '',
                phone: '',
                email: '',
                password: '',
                state: 'JAL',
                company_type: 'services',
                company_size: 'small',
                admin_tool: 'none',
                huso_horario: 'CENTRO',
                system: '',

            },
            rfc_error: 0,
            email_error: 0,
            show_password: false,
            submitted: false,
            states: [],
            statesOptions: [],
            regimens: [],
            regimensOptions: [],
        }
    },
    mounted: async function() {
      this.loading();
        let response = await general_ws.getRegimens(); 

        if(response.type == "success"){
          this.regimens = response.data;
        }

        let response2 = await general_ws.getStates(); 

        if(response2.type == "success"){
          this.states = response2.data;
        }

        this.regimensOptions = await this.$parseSelectOptionsOrdered(this.regimens, "code", "code:name");
        this.statesOptions = await this.$parseSelectOptionsOrdered(this.states, "code", "name");
      this.loaded();
    },
    methods: {
      async singup () {
        this.loading();          
          this.$v.$touch();

          if(!this.validatePassword(this.form.password)){
            this.loaded();
            return false;
          }
          let resRFC = await this.validateRFC(this.form.rfc);
          if(!resRFC){
            this.loaded();
            return false;
          }
          let resEmail = await this.validateEmail(this.form.email);
          if(!resEmail){
            this.loaded();
            return false;
          }

          if (this.isValid) {
            this.submitted = true;
                
            let response = await login.storeAccount(this.form);                
            if(response.type == "success"){
              //SI SE DEFINIÓ LA CONTRASEÑA SE HACE EL PRIMER LOGIN
              if(this.form.password.length >= 8){
                let params = {
                  email: this.form.email,
                  password: this.form.password,
                  device_name: navigator.userAgent
                };

                let response = await login.login(params);
                if(response.type == "success"){
                  let data = response.data;
                  localStorage.setItem("token", data.access_token);
                  axios.defaults.headers.common['access-token'] = data.access_token;
                  router.push("dashboard");
                }
              }
              //SI NO SE DEFINIÓ LA CONTRASEÑA - SE ENVIA UN CORREO DE ACTIVACIÓN                  
              this.is_finished = true;
            }else{
              this.loaded();
              this.showToast(response.type,response.message);
              return false;
            }
          }
        this.loaded();            
      },
      back(){
        router.push("login");
      },
        async validateRFC (rfc) {
            let response = await login.validateRFC(rfc);            
            if(response.type == "success" && response.data == 1){
              this.showToast("error",response.message);
              this.form.rfc = "";

              return false;
            }

            return true;
        },
        async validateEmail (email) {
            let response = await login.validateEmail(email);

            if(response.type == "success" && response.data == 1){              
              this.showToast("error",response.message);
              this.form.email = "";

              return false;
            }

            return true;
        },
        validatePassword (password) {
            if(password.length > 0 && password.length < 8){
              this.showToast("error","El campo contraseña debe tener al menos 8 caracteres");
              return false;
            }

            return true;
        },
        checkIfValid (fieldName) {
            const field = this.$v.form[fieldName]
            if (!field.$dirty) {
                return null
            }

            if(fieldName == "email"){
              if(field.$model == "raalveco@gmail.com"){
                this.show_password = true;
              }
            }
            
            return !(field.$invalid)
        },
        showToast(type, message){
            store.commit('toast', {color: type, message: message});
        },
        loading(){
            store.commit('loading');
        },
        loaded(){
            store.commit('loaded');
        }
    },
    computed: {
      formString () { return JSON.stringify(this.form, null, 4) },
      isValid () { return !this.$v.form.$invalid },
      isDirty () { return this.$v.form.$anyDirty },
      visible() {
        return store.state.isLoading;
      },
      toasts () {
        return store.state.toasts;
      },
    },
    mixins: [validationMixin],
    validations: {
        form: {
            type: {
                required
            },
            rfc: {
                required
            },
            name: {
                required
            },
            regimen: {
                required
            },
            commercial_name: {
                required
            },
            phone: {
                required
            },
            email: {
                required,
                email
            },
            password: {},
            state: {
                required
            },
            huso_horario: {},
            company_type: {
                required
            },
            company_size: {
                required
            },
            admin_tool: {
                required
            },
            system: {}
        }
    },
}
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>